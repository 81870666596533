<template>
  <div class="modal">
    <div :class="`card card__modal`">
      <div class="card__header">
        <h4 class="card__title">{{ $t('modify-columns') }}</h4>
        <button class="btn btn--close-modal" @click.prevent="hideModal">
          <img src="/img/baseline_clear_white_48dp.png" :alt="$t('cancel')" :title="$t('cancel')" class="btn__img btn__img--small">
        </button>
      </div>
      <div class="card__content">
        <div class="card__content-columns">
          <!-- <h5 class="column-title">{{ $t('select-columns') }}</h5> -->
          <input type="text" class="input__search" :placeholder="$t('search')" v-model="searchText">
          <h5 class="column-title">{{ $t('your-columns') }}</h5>
          <ol class="content-column">
            <!-- <li class="column-row"><input type="text" class="input__search" :placeholder="$t('search')" v-model="searchText"></li> -->
            <template v-for="(column, columnIndex) in columnsMeta">
              <li :key="columnIndex" class="column-row" v-if="column.label.toUpperCase().indexOf(searchText.toUpperCase()) > -1">
                <input type="checkbox" class="input__checkbox" :class="[ columnsSelection.includes(column.id) ? 'input__checkbox--checked' : '' ]" :id="`column-id-${column.id}`" :name="`column-id-${column.id}`" :value="column.id" :checked="columnsSelection.includes(column.id)" @change="onColumnsSelectionChange">
                <label class="input__label input__label--checkbox" :for="`column-id-${column.id}`">{{ column.label }}</label>
              </li>
            </template>
          </ol>
          <ol class="content-column tag-container drop-zone" @drop="onDrop($event)" @dragover.prevent @dragenter.prevent>
            <li v-for="(columnId, columnIndex) in columnsSelection" :key="columnIndex" draggable
              @dragstart="startDrag($event, columnId)"
              @dragend="endDrag($event, columnId)"
              @dragover="overDrag($event, columnId)"
              class="column-row tag"
              :class="{ '--dragging' : itemBeignDragged === columnId, '--over': itemBeignDraggedOver === columnId }">
              <img class="btn__img btn__img--small" src="/img/baseline_drag_handle_white_48dp.png" :alt="$t('drag')">
              {{ $parent.getColumnsMeta(columnId).label }}
              <img class="btn__img btn__img--small --clear-cursor" src="/img/baseline_cancel_white_48dp.png" :alt="$t('clear')" @click.prevent="removeColumn(columnId)">
            </li>
          </ol>
        </div>
        <div class="modal__actions">
          <div class="modal__actions--left">
            <button
              @click="hideModal"
              type="button" class="btn btn--text">{{ $t('cancel') }}</button>
          </div>
          <div class="modal__actions--right">
            <button
              @click="changeColumns"
              type="button" class="btn btn--ok">{{ $t('update') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// USO:
// <!-- COLUMNS-SELECTOR-MODAL -->
// <columns-selector
//   id="columns-filter-selector"
//   v-if="isColumnsSelectorVisible"
//   :columns="columnsFilter"
//   v-on:on-columns-filter-hide-modal="onColumnsFilterHideModal"
//   v-on:on-columns-filter-change="onColumnsFilterChange"></columns-selector>
// DRAG & DROP API: https://web.dev/drag-and-drop/
export default {
  name: 'columns-selector',
  props: [
    'columnsFilter',
    'columnsMeta'
  ],
  data () {
    return {
      columnsSelection: [],
      itemBeignDragged: '',
      itemBeignDraggedOver: '',
      searchText: ''
    }
  },
  mounted () {
    this.columnsSelection = [...this.columnsFilter]
  },
  methods: {
    hideModal () {
      this.$emit('on-columns-filter-hide-modal')
    },
    changeColumns () {
      this.$emit('on-columns-filter-change', { columnsFilter: [...this.columnsSelection] })
      this.hideModal()
    },
    removeColumn (columnId) {
      this.columnsSelection = [...this.columnsSelection.filter(item => item !== columnId)]
    },
    startDrag (e, itemId) {
      this.itemBeignDragged = itemId
    },
    endDrag (e, itemId) {
      this.itemBeignDragged = ''
      this.itemBeignDraggedOver = ''
    },
    overDrag (e, itemId) {
      this.itemBeignDraggedOver = itemId
    },
    // EVENTS
    onColumnsSelectionChange (e) {
      if (e.target.checked) {
        this.columnsSelection = [...this.columnsSelection, e.target.value]
      } else {
        this.columnsSelection = [...this.columnsSelection.filter(item => item !== e.target.value)]
      }
    },
    onDrop (e) {
      const from = this.columnsSelection.indexOf(this.itemBeignDragged)
      const to = this.columnsSelection.indexOf(this.itemBeignDraggedOver)
      this.columnsSelection[from] = this.itemBeignDraggedOver
      this.columnsSelection[to] = this.itemBeignDragged
    }
  }
}
</script>
<i18n src="@/core/i18n-common.json"></i18n>
<i18n>
{
  "en": {
    "modify-columns": "Modify columns",
    "select-columns": "Select columns",
    "your-columns": "Your columns",
    "drag": "Drag"
  },
  "es": {
    "modify-columns": "Modificar columnas",
    "select-columns": "Seleccionar columnas",
    "your-columns": "Tus columnas",
    "drag": "Mover"
  }
}
</i18n>
<style scoped>
.modal {
  position: absolute;
  padding: 0;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-content: center;
  box-shadow: 0 0 50px var(--app-shadow-color);
  border-radius: var(--card-border-radius);
}
.card__modal {
  width: 100%;
  max-width: calc(100% - 20px);
  min-height: 300px;
  margin: 0;
  padding: 30px;
  border-radius: 30px;
  display: grid;
  grid-template-rows: 60px 1fr;
  overflow: visible;
  background-color: var(--app-white);
}
.card__header {
  padding-bottom: 30px;
}
.card__title {
  font-size: 24px;
  line-height: 30px;
  height: 30px;
  font-weight: var(--app-font-bold);
}
.card__content {
  padding: 0;
}
.card__content-columns {
  display: grid;
  grid-template-rows: 30px 300px;
  grid-template-columns: 280px 280px;
  gap: 20px;
}
.content-column {
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}
.column-row {
  margin-bottom: 10px;
}
@media (min-width: 460px) {
  .card__modal {
    max-width: calc(100% - 50px);
  }
}
@media (min-width: 800px) {
  .card__modal {
    max-width: 800px;
  }
}
</style>
